@import url("https://fonts.googleapis.com/css2?family=Fredoka:wght@300;400;500;600;700&display=swap");

* {
  box-sizing: border-box;
  scroll-behavior: smooth;

  font-family: "Fredoka", sans-serif !important;
}

body {
  margin: 0;
  background-image: require("../assets/background.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
::-webkit-scrollbar {
  width: 12px; /* Adjust the width as needed */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Track color */
}

::-webkit-scrollbar-thumb {
  background-color: #71aa19; /* Thumb color */
  border-radius: 10px; /* Round the thumb */
}

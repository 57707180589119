.swiper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.swiper-slide {
}

.swiper-slide img {
}
s .swiper-backface-hidden .swiper-slide {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  display: flex;
}
.button-36 {
  background-image: linear-gradient(
    92.88deg,
    #72ab19 9.16%,

    #72ab19 64.72%
  );
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  flex-shrink: 0;
  font-family: "Inter UI", "SF Pro Display", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-size: 27px;
  font-weight: 500;
  height: 2.7em;
  padding: 0 1.6rem;
  width: 300px;
  text-align: center;
  text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
  transition: all 0.5s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
.swiper-wrapper {
  justify-content: space-evenly !important    ;
}

.button-36:hover {
  box-shadow: rgba(162, 205, 63, 0.5) 0 1px 30px;
  transition-duration: 0.1s;
}

@media (min-width: 768px) {
  .button-36 {
    padding: 0 2.6rem;
  }
}
.music-player-container {
  display: flex;
  position: absolute;

  flex-direction: column;
  align-items: center;

  height: 146px;

  min-width: 460px;
}

.music-player-container:after {
  -webkit-filter: blur(8px);
  filter: blur(8px);
  background-color: rgba(0, 0, 0, 0.8);
  bottom: -2px;
  content: " ";
  display: block;
  height: 10px;
  left: 19px;
  position: absolute;
  transform: rotate(-3deg);
  width: 70%;
  z-index: 0;
}

.music-player {
  background-color: #9999cc;
  height: 370px;
  padding: 40px 250px 40px 40px;
  position: absolute;
  text-align: right;
  width: 460px;
  z-index: 3;
}

.player-content-container {
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  top: 50%;
  position: relative;
}

.artist-name {
  font-size: 28px;
  font-weight: normal;
  margin: 0 0 0.75em 0;
}

.album-title {
  font-weight: 200;
  font-size: 24px;
  margin: 0 0 1.75em 0;
}

.song-title {
  font-size: 18px;
  font-weight: 200;
  margin: 0 0 1.5em 0;
}

.album {
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.65);
  height: 315px;
  margin-left: 334px;
  margin-top: 27px;
  position: relative;
  width: 315px;
  z-index: 10;
}

.album-art {
  background: #fff url("./cd.jpeg") center/cover no-repeat;
  height: 315px;
  position: relative;
  width: 315px;
  z-index: 10;
}

.vinyl {
  -webkit-animation: spin 2s linear infinite;
  -moz-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  -webkit-transition: all 500ms;
  -moz-transition: all 500ms;
  transition: all 500ms;
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/83141/vinyl.png"),
    url("./cd.jpeg");
  background-position: center, center;
  background-size: cover, 40% auto;
  background-repeat: no-repeat;
  border-radius: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  height: 300px;
  left: 0;
  position: absolute;
  top: 5px;
  width: 300px;
  z-index: 5;
  will-change: transform, left;
}
.is-playing .vinyl {
  left: 52%;
}

.music-player-controls {
  text-align: center;
}

[class^="control-"] {
  -webkit-filter: brightness(95%);
  filter: brightness(95%);
  border-radius: 100%;
  display: inline-block;
  height: 44px;
  margin: 0 3px;
  width: 44px;
}
[class^="control-"]:hover {
  -webkit-filter: brightness(85%);
  filter: brightness(85%);
  cursor: pointer;
}

.control-play {
  background: transparent
    url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/83141/play.svg")
    center/cover no-repeat;
}
.is-playing .control-play {
  background: transparent
    url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/83141/pause.svg")
    center/cover no-repeat;
}

.control-forwards {
  background: transparent
    url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/83141/forwards.svg")
    center/cover no-repeat;
}

.control-back {
  background: transparent
    url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/83141/backwards.svg")
    center/cover no-repeat;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* General styles, applicable to all sizes, already provided in your CSS */
@media screen and (max-width: 1300px) {
  .album-art {
    background: #fff url("./cd.jpeg") center/cover no-repeat;
    height: 315px;
    position: relative;
    width: 322px;
    z-index: 10;
  }
  .album {
    box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.65);
    height: 315px;
    margin-left: 21rem;
    margin-top: 27px;
    position: relative;
    width: 318px;
    z-index: 10;
  }
  .music-player-container {
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: center;
    height: 146px;
    min-width: 0px;
  }
}
/* Tablet adjustments */
@media (max-width: 1000px) {
  .button-36 {
    font-size: 22px; /* Slightly smaller font size for tablets */
    padding: 0 2rem; /* Adjust padding */
    width: 250px; /* Adjust width to fit smaller screens */
  }
  .is-playing .vinyl {
    left: 26%;
    top: -1px;
  }
  .music-player-controls {
    text-align: right;
  }
  .music-player-container {
    flex-direction: column;
    height: auto; /* Adjust height to be more flexible */
    min-width: 0; /* Remove minimum width */
  }

  .music-player {
    padding: 20px;
    width: 70%;
    left: 0;
    height: 300px;
  }

  .album {
    margin-top: 20px; /* Adjust margin top */
    width: 251px;
    height: 213px;
    width: 213px;
  }
  .player-content-container {
    transform: translateY(-50%);
    top: 50%;
    right: 117px;
    position: relative;
  }

  .vinyl {
    height: 250px; /* Adjust size of the album and vinyl */
    width: 250px;
    left: -44px;
  }
  .album-art {
    height: 259px;
    width: 259px;
  }
}
@media screen and (max-width: 768px) {
  .vinyl {
    height: 200px; /* Adjust size of the album and vinyl */
    width: 200px;
  }
  .album-art {
    height: 230px;
    width: 267px;
  }
}
@media (max-width: 480px) {
  .button-36 {
    font-size: 18px; /* Further reduce font size for phones */
    padding: 0 1rem; /* Adjust padding for tighter spaces */
    width: 200px; /* Adjust width for smaller screen sizes */
  }

  .music-player-container {
    /* Adjust the music player container for mobile */
    flex-direction: column;
    height: auto; /* Allows the container to grow as needed */
    min-width: 0; /* Remove minimum width constraint */
    width: 100%; /* Use the full width of the screen */
    margin: 0 auto; /* Center the container on the screen */
    padding: 20px 0; /* Add some vertical padding */
  }

  .music-player {
    /* Adjust the main music player styling for mobile */
    padding: 10px; /* Reduce padding to save space */
    position: relative; /* Adjust position if needed */
    text-align: center; /* Center the text for RTL languages */
    height: 214px;
  }

  .album {
    height: 200px; /* Smaller height for mobile devices */
    width: 200px; /* Smaller width to match the height */
    margin-top: -50px;
    margin-left: 71px;
    margin-right: auto; /* Center alignment */
    background-size: cover, 30% auto; /* Adjust background size for a smaller vinyl look */
  }
  .jeiOlt {
    height: 64vh !important;
  }
  .vinyl {
    height: 200px;
    width: 200px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    background-size: cover, 48% auto;
  }
  .is-playing .vinyl {
    left: 46%;
    top: -1px;
  }
  .music-player-controls {
    /* Adjust music player controls for mobile */
    text-align: center; /* Center the controls for RTL languages */
  }
  .player-content-container {
    /* Adjust player content container for mobile */
    right: 0;
    top: initial; /* Reset top positioning */
    transform: translateY(0%); /* Reset transform */
    padding-top: 20px; /* Add padding at the top for spacing */
  }

  .artist-name,
  .album-title,
  .song-title {
    /* Adjust text sizes for readability on small screens */
    font-size: 16px; /* Smaller font size for mobile */
  }

  [class^="control-"] {
    /* Adjust control button sizes for easier interaction on mobile */
    height: 40px; /* Slightly smaller buttons */
    width: 40px; /* Match button height for square appearance */
    margin: 0 10px; /* Increase margin for easier tapping */
  }
}

.social-media-nav {
  position: fixed;
  top: 15vh;
  left: 0;
  z-index: 10000;
}
.img_wechat {
  width: 30rem;
}

.social-media-nav ul {
  display: block;
  list-style-type: none;
  padding-left: 0;
  text-align: center;
}

.side-social {
  width: 90px;
  height: 80px;
  line-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 40px;
  color: #fff;
  box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.7);
  transition: 0.2s;
}

.side-social:hover {
  width: 100px;
  transition: 0.5s;
}

.facebook {
  background: #3b5998;
  border-radius: 0 30% 0 0;
}

.wechat {
  background: #09b83e;
}

.eventbrite {
  background: #ff8000;
}

.instagram {
  background: #fccc63;
  border-radius: 0 0 30% 0;
}

.linkedin {
  background: #0e76a8;
  border-radius: 0 0 30% 0;
}
.spotify {
  background: #ffffff;
}
.facebook {
  background: #3b5998;
  border-radius: 0 30% 0 0;
  width: 89px; /* Adjust width for smaller screens */
  height: 60px; /* Adjust height for smaller screens */
}

/* Adjust size and positioning for smaller screens */
@media (max-width: 768px) {
  .facebook {
    width: 60px;
    height: 60px;
    border-radius: 0;
  }
  .social-media-nav {
    position: fixed;
    top: 38vh;
    left: 0;
    z-index: 10000;
  }
  .wechat {
    width: 60px;
    height: 50px;
    border-radius: 0; /* Remove border radius for square appearance */
  }
  .eventbrite {
    width: 60px;
    height: 50px;
    border-radius: 0; /* Remove border radius for square appearance */
  }
  .instagram {
    width: 60px;
    height: 50px;
    border-radius: 0; /* Remove border radius for square appearance */
  }
  .linkedin {
    width: 60px;
    height: 50px;
    border-radius: 0; /* Remove border radius for square appearance */
  }
  .spotify {
    width: 60px;
    height: 50px;
    border-radius: 0; /* Remove border radius for square appearance */
  }
  .side-social {
    width: 70px;
    height: 60px;
    font-size: 30px;
    line-height: 40px;
  }
  .side-social:hover {
    width: 70px;
    transition: 0.3s;
  }
}

@media (max-width: 480px) {
}
